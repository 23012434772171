/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import '../style/main.scss'
import Layout from "./layout";

const PageLayout = ({ children, pageName, pageTitle }) => {
  return (
    <Layout pageName={pageName}>
      <div className="post">
        <header className="post-header">
          <h1 className="post-title">{pageTitle}</h1>
        </header>

        <article className="post-content">
          {children}
        </article>
      </div>
    </Layout>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
