import React from "react"

import PageLayout from "../components/pageLayout"
import SEO from "../components/seo"
import imgHead from '../images/head.png'
import imgEyeBackground from '../images/eyes-background.png'
import imgEye from '../images/eye.png'
import '../style/face.scss'

const Page = () => (
  <PageLayout pageName="credits" pageTitle="Credits">
    Credit where credit's due

    <dl>
      <dt>Namecalling</dt><dd><a href="http://jontangerine.com/">Jon Tan</a>.</dd>
      <dt>Action Man Critique</dt><dd><a href="http://chloeeardley.co.uk/">Chloë Eardley</a>.</dd>
      <dt>Action Man Redesign</dt><dd><a href="http://tomoliverharrison.co.uk/">Tom Harrison</a>.</dd>
    </dl>


  </PageLayout>
)

export default Page